import {TRANSACTION_TYPE, WALLET_OWNER_TYPE} from "constants/common.constants";

export const getExportFilters = (type, filters) => {
    const allTransactionTypes = type === WALLET_OWNER_TYPE.CASHIER ?
        [
            TRANSACTION_TYPE.PLAYER_DEPOSIT,
            TRANSACTION_TYPE.PLAYER_WITHDRAWAL,
            TRANSACTION_TYPE.BET,
            TRANSACTION_TYPE.CANCEL_BET,
            TRANSACTION_TYPE.PAIDOUT,
            TRANSACTION_TYPE.REJECT_BET,
            TRANSACTION_TYPE.RECALCULATE,
            TRANSACTION_TYPE.WON,
            TRANSACTION_TYPE.BETSHOP_CASHIER_HANDOVER_CASHIER
        ] :
        [
            TRANSACTION_TYPE.BETSHOP_MANAGER_DEPOSIT_CASHIER,
            TRANSACTION_TYPE.BETSHOP_MANAGER_WITHDRAW_CASHIER,
        ]

    return {
        ...filters,
        transactionTypes: filters.transactionTypes ? [filters.transactionTypes] : allTransactionTypes,
    }
}
