import React from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import useFormat from 'hooks/useFormat';

import userInfoType from 'types/userInfo.type';

/** Balance section in header */
const Balance = ({
    userInfo
}) => {
    const { t } = useTranslation();

    const currency = userInfo?.currencies?.[0] ?? null;
    
    const { formatAmount } = useFormat();

    return (
        <div className='rt--flex rt--cashier-header-balance rt--pl-24 rt--pr-24'>
            <div className='rt--flex rt--align-center rt--justify-center rt--flex-equal' data-type="limit">
                <i className='icon-arrow-growth rt--font-bigest' />
                <div className='rt--flex-col rt--flex rt--text-left rt--ml-8'>
                    <span className='rt--title rt--font-normal rt--font-regular rt--pb-2'>{t('common.limit')}</span>
                    <b className='rt--title rt--font-big rt--font-medium'>
                        {
                            `${formatAmount(userInfo?.limit ?? 0, currency?.code)} ${currency?.code ?? ""}`
                        }
                    </b>
                </div>
            </div>

            <div className='rt--flex rt--align-center rt--justify-center rt--flex-equal' data-type="balance">
                <i className='icon-coins rt--font-bigest' />
                <div className='rt--flex-col rt--flex rt--text-left rt--ml-8'>
                    <span className='rt--title rt--font-normal rt--font-regular rt--pb-2'>{t('common.balance')}</span>
                    <b className='rt--title rt--font-big rt--font-medium'>
                        {
                            `${formatAmount(userInfo?.balance ?? 0, currency?.code)} ${currency?.code ?? ""}`
                        }
                    </b>
                </div>
            </div>
        </div>
    )

}

/** Balance propTypes
    * PropTypes
*/
Balance.propTypes = {
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo
    }
}

export default connect(
    mapStateToProps,
    null
)(
    Balance
);
