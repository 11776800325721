import React from "react";

import moment from "moment/moment";

import { getBetStateText } from "utils/common";

import { PROJECT_PROVIDER_TYPE, DATE_TIME_FORMAT } from "constants/common.constants";
import { BET_STATE, BET_TYPE } from "constants/bets.constants";

export const getColumns = ({
    canPlaceAnonymousBets,
    t,
    formatAmount,
}) => [
    {
        title: t("common.id"),
        key: "id",
        copy: true
    },
    ...(!canPlaceAnonymousBets ? [
        {
            title: t("common.playerId"),
            key: "playerId",
            copy: true
        },
        {
            title: t("common.playerUsername"),
            key: "playerUserName",
        },
    ] : []),
    {
        key: 'betTime',
        title: t('common.betDate'),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
        sorting: true
    },
    {
        key: "provider",
        title: t("common.provider"),
        render: value => (
            <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{
                value === PROJECT_PROVIDER_TYPE.SPORTBOOK ? t("common.sportsBook") : 
                value === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS ? t("common.virtualSport") : 
                value === PROJECT_PROVIDER_TYPE.GOLDEN_RACE ? t("common.goldenRace") : ""
            }</span>
        ),
        sorting: true
    },
    {
        key: "projectName",
        title: t("common.betshop"),
    },
    {
        key: 'status',
        title: t('common.status'),
        render: (value, record) => {
            let status = value;

            if (record.paidoutTime) {
                status = BET_STATE.PAID_OUT;
            }

            if (record.isCashedout) {
                status = BET_STATE.CASHED_OUT;
            }

            return (
                <span
                    className="rt--title rt--font-normal rt--font-regular rt--table-bets-status"
                    data-state={status}
                >
                    {getBetStateText(status)}
                </span>
            );
        },
        sorting: true
    },
    {
        key: "betType",
        title: t("common.betType"),
        render: value => (
            <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{value === BET_TYPE.SINGLE ? t("common.single") : value === BET_TYPE.MULTI ? t("common.multi") : t("common.system")}</span>
        ),
        sorting: true
    },
    {
        key: 'amount',
        title: t('common.amount'),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(Number(value), record.currencyCode)}</span>,
        sorting: true
    },
    {
        key: 'currencyCode',
        title: t('common.currency'),
    },
    {
        key: 'factor',
        title: t('common.odds'),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{record.betType === BET_TYPE.SINGLE && record.bets.length > 1 ? "-" : Number(value).toFixed(2) + "x"}</span>
    },
    {
        key: 'calculateTime',
        title: t('common.calculatedAt'),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
    },
    {
        key: 'possibleWin',
        title: t('common.possibleWin'),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(Number(value), record.currencyCode)}</span>,
    },
    {
        key: 'winning',
        title: t('common.win'),
        render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{value ? formatAmount(Number(value), record.currencyCode) : "-"}</span>,
    },
    {
        key: 'userName',
        title: t('common.placedBy')
    },
    {
        key: 'paidoutBy',
        title: t('common.payoutBy'),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? value : "-"}</span>,
    },
    {
        key: 'paidoutTime',
        title: t('common.payoutDate'),
        render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
    },
];
